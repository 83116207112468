import 'src/components/molecules/card/index.scss';
import React, { useEffect } from 'react';
import { useConfig, useModal, useTheme } from 'src/hooks';
import Button from 'src/components/atoms/button';
import Icon from 'src/components/atoms/icon';
import Image from 'src/components/atoms/image';
import Link from 'src/components/atoms/link';
import QuantityAdjuster from 'src/components/atoms/quantity-adjuster';
import Separator from 'src/components/atoms/separator';
import ViewDetails from 'src/components/modals/view-details';
import { pushCustomEvent } from 'plugins/custom-plugin-adobe-launch';
import { toProduct } from 'src/helpers/transformers';

import type { Plan } from 'src/helpers/transformers/api/_types';

type Props = Plan & {
  onQuantityChange: (obj: { amount: number, plan: Plan }) => void;
  enableTheme?: boolean;
}

const getLinearGradient = ({ backgroundColor } : { backgroundColor: string}) => `linear-gradient(180deg, ${backgroundColor}26 0%, #D7D7D726 100%)`;

export default (props : Props) => {
  const { overrideStyles } = useConfig();

  const { onQuantityChange, enableTheme = false, ...plan } = props;
  const {
    category = 'HVAC', highlights = [], featuredPosition = 0, icon, isFeatured, maxQuantity = 1, minQuantity = 1, name, price, promotion, quantity
  } = plan;

  const isFeaturedCard = isFeatured && featuredPosition > 0 && featuredPosition <= 3 && enableTheme;

  const gradients = [ useTheme('planCardCaseLabel'), useTheme('primary'), { backgroundColor: '#707070' } ];
  const theme = isFeaturedCard ? gradients[featuredPosition - 1] : gradients[0];
  const background = isFeaturedCard ? getLinearGradient(theme) : 'white';
  const alliedPlans = [ 'PEMPR-5k', 'PEPR-5k' ];
  const { showModal, updateModal } = useModal();
  const modalKey = 'view-details';
  const selectPlan = (plan: Plan, amount?: number) => {
    if(alliedPlans.includes(plan.id)) {
      sessionStorage.setItem('alliedElectricPlanId', plan.id);
    }
    onQuantityChange({ amount: amount ?? 1, plan });
  };

  useEffect(() => {
    updateModal({ innerContent: <ViewDetails key={modalKey} {...props} /> });
  }, [ quantity ]);

  const showViewDetailsModal = () => {
    pushCustomEvent({ event: 'productDetailsView', product: toProduct(plan) });
    showModal({ innerContent: <ViewDetails key={modalKey} {...props} /> });
    sessionStorage.setItem('ViewDetailsModal', 'true');
  };

  const PromoBanner = () => (
    <div className='card--promo-banner' style={{ backgroundColor: theme?.backgroundColor }} >
      <div className='card--promo-banner--label'>{promotion}</div>
    </div>
  );

  const Pricing = () => (
    <div className='card--pricing'>
      <div className='card--pricing--price' >
        <span className='card--pricing--price--amount'>{price?.formattedMonthlyAmount}</span>
        <span className='card--pricing--price--mo'>/mo</span>
      </div>
      <div className='card--pricing--term'>12 month plan</div>
    </div>
  );

  const Highlights = () => (
    <div className='card--highlights'>
      {highlights?.map((highlight, index) => {
        return (
          <div key={index}>
            <div className='card--highlights--li'>
              <span className='card--highlights--li--icon'><Icon type='check'/></span>
              <span className='card--highlights--li--label'>{highlight}</span>
            </div>
            <Separator/>
          </div>
        );
      })}
    </div>
  );

  const CartButton = () => {
    const selectedPlanId = sessionStorage.getItem('alliedElectricPlanId');
    if (quantity && maxQuantity > 1) {
      return <QuantityAdjuster max={maxQuantity} min={minQuantity} value={quantity} onChange={(amount) => onQuantityChange({ amount, plan })} />;
    }

    return quantity
      ? <Button className='button-card' label='Added!' theme='gray' disabled />
      : <Button className='button-card cta-btn' label='Add to cart' onClick={() => selectPlan(plan)}
        disabled={alliedPlans.includes(plan.id) && !!alliedPlans.find((id: string) => selectedPlanId === id)}
      />;
  };

  return price ? (
    <div className='card' style={{ background }}>
      <Image alt={`${category} icon`} className='card--icon' src={icon} />
      {promotion && <PromoBanner />}
      <div className='card--title'> {name} </div>
      <Pricing />
      <Highlights />
      <CartButton/>
      <Link className='card--view-details' hyperLinkClass={overrideStyles?.hyperLinkClass} label='View details' onClick={showViewDetailsModal} />
    </div>
  ) : null;
};
