import 'src/components/atoms/accordion/index.scss';
import React, { useState } from 'react';
import Icon from 'src/components/atoms/icon';

import type { ReactNode } from 'react';
import { useConfig } from 'src/hooks';

interface Props {
  title: string;
  children: ReactNode;
}

export default ({ title, children }: Props ) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const { overrideStyles } = useConfig();
  const type = isOpen ? 'arrowUp' : 'arrowDown';

  return(
    <div className='accordion'>
      <details>
        <summary onClick={() => setIsOpen(!isOpen)} aria-label={title}>
          <span className={`accordion--title ${overrideStyles?.hyperLinkClass}`}>{title}</span>
          <span className='accordion--icon'><Icon type={type}/></span>
        </summary>
        <div className='accordion--content'>
          {children}
        </div>
      </details>
    </div>
  );
};
