import 'src/components/atoms/quantity-adjuster/index.scss';
import Icon from 'src/components/atoms/icon';
import React from 'react';

interface Props {
  className?: string;
  max?: number;
  min?: number;
  onChange?: (amount: number) => void;
  value?: number;
}

export default ({ className = '', max = 10, min = 0, onChange, value = 1 }: Props) => {
  const isMinusDisabled = value <= min;
  const isAddDisabled = value === max;

  return (
    <div className={`quantity-adjuster ${className}`}>
      <button
        className={`quantity-adjuster--button ${isMinusDisabled ? 'disabled' : ''}`}
        disabled={isMinusDisabled}
        onClick={() => onChange?.(value - 1)}>
        <Icon type='minus'/>
      </button>
      <span>{value}</span>
      <button
        className={`quantity-adjuster--button ${isAddDisabled ? 'disabled' : ''}`}
        disabled={isAddDisabled}
        onClick={() => onChange?.(value + 1)}>
        <Icon type='add'/>
      </button>
    </div>
  );
};
