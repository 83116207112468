import 'src/components/modals/view-details/index.scss';

import Accordion from 'src/components/atoms/accordion';
import Button from 'src/components/atoms/button';
import Icon from 'src/components/atoms/icon';
import QuantityAdjuster from 'src/components/atoms/quantity-adjuster';
import React from 'react';
import Separator from 'src/components/atoms/separator';
import { useConfig } from 'src/hooks';

import type { Plan } from 'src/helpers/transformers/api/_types';

type Props = Plan & {
  onQuantityChange: (obj: { amount: number, plan: Plan }) => void;
}

export default (props: Props) => {
  const { onQuantityChange, ...plan } = props;
  const { coverages = [], highlights = [], limitations, maxQuantity = 1, minQuantity = 0, name, quantityNotes, notCovered, price, quantity } = plan;
  const { footer: { links = [] } = {} } = useConfig();

  let { termsAndConditions } = useConfig();
  const PEPlans = [ 'AA10057463', 'AA10057464', 'PEMPR-5k', 'PEPR-5k' ];

  if (PEPlans.includes(plan.id)) {
    links.forEach((link:any) => {
      if (link.label === 'Personal Electronics (T&Cs)' || link.label === 'Personal Electronics - Terms and Conditions (EN)') {
        termsAndConditions = link.href;
      }
    });
  }

  const Highlights = () => (
    <div className='view-details--highlights'>
      {highlights?.map((highlight, index) => {
        return (
          <div key={index}>
            <div className='view-details--highlights--li'>
              <span className='view-details--highlights--li--icon'><Icon type='check' /></span>
              <span className='view-details--highlights--li--label'>{highlight}</span>
            </div>
            <Separator />
          </div>
        );
      })}
    </div>
  );

  const CartButton = () => {
    if (quantity && maxQuantity > 1) {
      return <QuantityAdjuster max={maxQuantity} min={minQuantity} value={quantity} onChange={(amount) => onQuantityChange({ amount, plan })} />;
    }

    return quantity
      ? <Button className='view-details--button' label='Added!' theme='gray' disabled />
      : <Button className='view-details--button' label='Add to cart' theme='primary' onClick={() => onQuantityChange({ amount: 1, plan })} />;
  };

  const AccordionTermsAndConditions = () => (
    <div className='view-details--accordion-terms'>
      See our <a href={termsAndConditions} target='_blank' rel='noreferrer'>Sample Terms and Conditions </a> for full details
    </div>
  );

  return (
    <div className='view-details' >
      <div className='view-details--title'>{name}</div>
      <div className='view-details--mo'><span className='view-details--price'>{price?.formattedMonthlyAmount}</span>/mo</div>
      <div className='view-details--scroll'>
        <Highlights />
        {coverages && <>
          <Accordion title={'What\'s covered:'}>
            <ul>
              {coverages?.map((coverage, index) => <li key={index}>{coverage}</li>)}
            </ul>
            {termsAndConditions && <AccordionTermsAndConditions />}
          </Accordion>
          <Separator />
        </>}
        {maxQuantity > 1 && quantityNotes && <>
          <Accordion title='Unit quantity: Why do I need more?'>
            <div>{quantityNotes}</div>
          </Accordion>
          <Separator />
        </>}
        {notCovered && <>
          <Accordion title={'What\'s not covered:'}>
            <ul>
              {notCovered?.map((coverage, index) => <li key={index}>{coverage}</li>)}
            </ul>
            <AccordionTermsAndConditions />
          </Accordion>
          <Separator />
        </>}
        {limitations && <>
          <Accordion title='Limitations:'>
            {limitations?.map((limitation, index) => <div key={index}>{limitation}</div>)}
          </Accordion>
          <Separator />
        </>}
        <div className='view-details--terms'> <a href={termsAndConditions} target='_blank' rel='noreferrer'> Sample Terms and Conditions </a></div>
        <Separator />
      </div>
      <CartButton />
    </div>
  );
};
